<template>
  <section>
    <lottie-player :src="src" class="refusal-image" autoplay speed="1" loop />

    <h3>{{ $localize('loading')['title'] }}</h3>

    <p>{{ $localize('loading')['text'] }}</p>

    <main-button
      class="p-button"
      :is-loading="loading"
      :label="$localize('button')['checkStatus']"
      @click="checkStatus"
    />
  </section>
</template>

<script>
import animatedClock from '../../assets/json/anim';
import MainButton from '@/components/buttons/MainButton';

export default {
  name: 'loading',
  data() {
    return {
      src: JSON.stringify(animatedClock),
      loading: false
    };
  },
  components: { MainButton },
  computed: {
    purchasedProduct() {
      return this.$store.getters['buyers/purchasedProduct']
    }
  },
  created() {
    this.$store.dispatch('buyers/userStatus');
    console.log(this.purchasedProduct.hasPurchase);
  },
  methods: {
    checkStatus() {
      this.loading = true;

      this.$axios
        .get(`/buyer/detail`)
        .then((res) => {
          this.loading = false;
          const passportType = this.$store.getters['buyers/passportType'];

          if (passportType === 6) {
            switch (res.data.data.status) {
              case 0:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'auth' });
                });
                break;
              case 1:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'cardAdd' });
                });
                break;
              case 2 || 6:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$forceUpdate();
                });
                break;
              case 4:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({
                    name: 'profile-index',
                  });
                });
                break;
              case 5:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-passport' });
                });
                break;
              case 8:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'refusal' });
                });
                break;
              case 10:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-selfie-passport' });
                });
                break;
              case 11:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-address-passport' });
                });
                break;
              case 12:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'confidant' });
                });
                break;
              default:
                this.$toasted.clear();
                this.$forceUpdate();
                break;
            }
          } else if (passportType === 0) {
            switch (res.data.data.status) {
              case 0:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'auth' });
                });
                break;
              case 1:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'cardAdd' });
                });
                break;
              case 2 || 6:
                this.$toasted.clear();
                this.$toastSuccess(this.$localize('loading')['waiting'])
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$forceUpdate();
                });
                break;
              case 4:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({
                    name: 'profile-index',
                  });
                });
                break;
              case 5:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-id-card' });
                });
                break;
              case 8:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'refusal' });
                });
                break;
              case 10:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-selfie-id-card' });
                });
                break;
              case 11:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'upload-address-id-card' });
                });
                break;
              case 12:
                this.$toasted.clear();
                this.$store.dispatch('buyers/userStatus').then(() => {
                  this.$router.push({ name: 'confidant-id-card' });
                });
                break;
              default:
                this.$toasted.clear();
                this.$forceUpdate();
                break;
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toastError(e.message);
        })
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  background: $grey;
  height: 100vh;
  padding-top: 80px;
}

.refusal-image {
  width: 220px;
  height: 220px;
  margin: auto;
}

h3 {
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 24px;
  color: $black;
}

p {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $black;
  margin-top: 24px;
}

button.p-button {
  width: auto;
  display: flex;
  margin: 24px auto;
}
</style>
